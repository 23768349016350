import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SplashComponent } from './splash/splash.component';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { dashboardGuard } from './guards/dashboard.guard'

function isNative() {
  const navigator : any = window.navigator
  const userAgent = navigator.userAgent
  const normalizedUserAgent = userAgent.toLowerCase()
  const standalone = navigator.standalone

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  const isAndroid = /android/.test(normalizedUserAgent)
  const isSafari = /safari/.test(normalizedUserAgent)
  const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari)

  return isWebview
}

const routes: Routes = [
  { path: '', component: SplashComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [isNative() ? dashboardGuard : MsalGuard]
  },
  { path: 'postauth', component: LoadingPageComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
