import { Component, Input } from '@angular/core';
import { GraphContainer } from 'src/app/model/generic-graph';
import { IntersectionState } from '../section-layout/section-layout.component';

@Component({
  selector: 'app-sbu',
  templateUrl: './sbu.component.html',
  styleUrls: ['./sbu.component.scss']
})
export class SbuComponent {
  @Input() graphData!: GraphContainer;
  public isLoading: boolean = true;

  public onIntersectionChange(event: IntersectionState): void {
    if(event === IntersectionState.Inside) {
      this.isLoading = false;
    }
  }
}
