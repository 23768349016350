<ng-container *ngIf="!isLoading">
    <div echarts
        [options]="options"
        class="chart-container"
        (chartInit)="onChartInit($event)"
        [autoResize]="false"
        [merge]="mergeOptions"></div>
</ng-container>
<ng-container *ngIf="isLoading">
    <div class="chart-container">
        <app-loader></app-loader>
    </div>
</ng-container>