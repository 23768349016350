import { Component, Input } from '@angular/core';
import { GraphContainer } from 'src/app/model/generic-graph';
import { IntersectionState } from '../section-layout/section-layout.component';

@Component({
  selector: 'app-eps',
  templateUrl: './eps.component.html',
  styleUrls: ['./eps.component.scss'],
})
export class EpsComponent {
  @Input() graphData!: GraphContainer;
  public isLoading: boolean = true;

  constructor() { }

  public onIntersectionChange(event: IntersectionState): void {
    if(event === IntersectionState.Inside) {
      this.isLoading = false;
    }
  }
}
