
<app-section-layout [dataForChart]="graphData" [itemTemplate]="FCFGraph" (onIntersection)="onIntersectionChange($event)"></app-section-layout>

<ng-template
 #FCFGraph
 let-data="data"
 let-componentState="componentState"
 let-setComponentState="setComponentState"
>
<app-fcf-slide
    [data]="data"
    (onUserSelectedData)="setComponentState($event)"
    [currentData]="componentState"
    [isLoading]="isLoading"
></app-fcf-slide>
</ng-template>
