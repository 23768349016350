<div class="production-kmt-slide">
    <div class="cells-container">
        <app-production-kmt-cell [shouldAnimate]="shouldAnimate" [cellDetails]="productionKpiGraph[0]" [cellNumber]="1" class="cell1" (selectCellEvent)="navigateToGraph($event)"></app-production-kmt-cell>
        <app-production-kmt-cell [shouldAnimate]="shouldAnimate" [cellDetails]="productionKpiGraph[1]" [cellNumber]="2" class="cell2" (selectCellEvent)="navigateToGraph($event)"></app-production-kmt-cell>
    </div>
    <div class="cells-container">
        <app-production-kmt-cell [shouldAnimate]="shouldAnimate" [cellDetails]="productionKpiGraph[2]" [cellNumber]="3" class="cell3" (selectCellEvent)="navigateToGraph($event)"></app-production-kmt-cell>
        <app-production-kmt-cell [shouldAnimate]="shouldAnimate" [cellDetails]="productionKpiGraph[3]" [cellNumber]="4" class="cell4" (selectCellEvent)="navigateToGraph($event)"></app-production-kmt-cell>
    </div>
    <div class="cells-container">
        <app-production-kmt-cell [shouldAnimate]="shouldAnimate" [cellDetails]="productionKpiGraph[4]" [cellNumber]="5" class="cell5" (selectCellEvent)="navigateToGraph($event)"></app-production-kmt-cell>
        <app-production-kmt-cell [shouldAnimate]="shouldAnimate" [cellDetails]="productionKpiGraph[5]" [cellNumber]="6" class="cell6" (selectCellEvent)="navigateToGraph($event)"></app-production-kmt-cell>
        <app-production-kmt-cell [shouldAnimate]="shouldAnimate" [cellDetails]="productionKpiGraph[6]" [cellNumber]="7" class="cell7" (selectCellEvent)="navigateToGraph($event)"></app-production-kmt-cell>
    </div>
    <div class="cells-container">
        <app-production-kmt-cell [shouldAnimate]="shouldAnimate" [cellDetails]="productionKpiGraph[7]" [cellNumber]="8" class="cell8" (selectCellEvent)="navigateToGraph($event)"></app-production-kmt-cell>
        <app-production-kmt-cell [shouldAnimate]="shouldAnimate" [cellDetails]="productionKpiGraph[8]" [cellNumber]="9" class="cell9" (selectCellEvent)="navigateToGraph($event)"></app-production-kmt-cell>
        <app-production-kmt-cell [shouldAnimate]="shouldAnimate" [cellDetails]="productionKpiGraph[9]" [cellNumber]="10" class="cell10" (selectCellEvent)="navigateToGraph($event)"></app-production-kmt-cell>
    </div>
</div>