import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductionFinVizView } from 'src/app/model/production-views';

@Component({
  selector: 'app-production-kmt-cell',
  templateUrl: './production-kmt-cell.component.html',
  styleUrls: ['./production-kmt-cell.component.scss']
})
export class ProductionKmtCellComponent implements OnDestroy {
  @Input() cellDetails:any = {} as ProductionFinVizView;
  @Input() cellNumber:number = 0;
  @Input() shouldAnimate: boolean = false;

  duration = 1000;

  @Output() selectCellEvent = new EventEmitter<string>();
  private _destroyed$ = new Subject();


  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }

  public isAboveDeltaThreshold(deltaPercent: number, deltaValue: number):boolean {
    if(deltaPercent === 0) {
      if(deltaValue >= 0) {
        return true;
      }else {
        return false;
      }
    }else {
      if(deltaPercent > 0) {
        return true;
      }else {
        return false;
      }
    }
  }
  
  public selectCell(cellDetails: any): void {
    this.selectCellEvent.emit(cellDetails);
  }
}
