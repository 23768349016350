import { Component, Input } from '@angular/core';
import { GraphContainer } from 'src/app/model/generic-graph';

@Component({
  selector: 'app-oi-by-sbu',
  templateUrl: './oi-by-sbu.component.html',
  styleUrls: ['./oi-by-sbu.component.scss']
})
export class OiBySbuComponent {
  @Input() graphData!: GraphContainer;
}
