import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNegativeNumber'
})
export class FormatNegativeNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (value < 0) {
      return `(${Math.abs(value)})`;
    } else {
      return value.toString();
    }
  }
}