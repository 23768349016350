import { Component, Input } from '@angular/core';
import { Animations } from 'src/app/animations';
import { PotashPriceRow, PotashPricesView } from 'src/app/model/potash-prices';

@Component({
  selector: 'app-prices-table',
  templateUrl: './prices-table.component.html',
  styleUrls: ['./prices-table.component.scss'],
  animations: [
    Animations.openClose
  ]
})
export class PricesTableComponent {
  @Input() data: PotashPricesView = {} as PotashPricesView;

  public currentIndex: number | null = null;
  private maxValue: number = 0;
  private minValue: number = 0;
  private delta: number = 0;

  ngOnInit(): void {
    this.calcBaseValues();
    this.data.data.rows.push(this.data.data.total);
  }

  private calcBaseValues(): void {
    let minPerRow: number [] = [];
    let maxPerRow: number[] = [];
    this.data.data.rows.forEach((row: PotashPriceRow) => {
      minPerRow.push(Math.min(...row.data));
      maxPerRow.push(Math.max(...row.data));
    });

    this.maxValue = Math.max(...maxPerRow);
    this.minValue = Math.min(...minPerRow);
    this.delta = this.maxValue - this.minValue;
  }

  public toggleRecordSelection(index: number): void {
    if (this.currentIndex === index) {
      this.currentIndex = null;
    }
    else {
      this.currentIndex = index;
    }
  }

  public eVolumeFun(value : number) : string{
    const ratio = (value - this.minValue)/(this.delta);
    return `rgb(${218 - Math.round(ratio * 109)},${222 - Math.round(ratio * 38)},${146 - Math.round(ratio * 19)})`;
  }
}
