import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { GraphContainer } from 'src/app/model/generic-graph';
import { NavigationOptions } from 'src/app/model/queryParams';
import { DataService } from 'src/app/services/data.service';
import { IntersectionState } from '../section-layout/section-layout.component';

@Component({
  selector: 'app-vertical-view',
  templateUrl: './vertical-view.component.html',
  styleUrls: ['./vertical-view.component.scss']
})
export class VerticalViewComponent {
  @Input() graphData!: GraphContainer;

  private _dataChanged$ = new Subject();
  public currentBuIndex: number = 0;
  public currentSbuIndex: number = 0;
  public currentData: any;
  public isLoading: boolean = true;

  public navState: NavigationOptions = {};

  constructor(private cdr: ChangeDetectorRef) { }

  public updateNavState(state: NavigationOptions){
    this.navState = state;
    if(this.navState.bu !== undefined){
      this.currentBuIndex = +this.navState.bu;
    }
    if(this.navState.sbu !== undefined){
      this.currentSbuIndex = +this.navState.sbu;
    }
    this.cdr.detectChanges();
  }

  public onDataChanged(event: any): void {
    this._dataChanged$.next(event);
  }

  public onUserSelectedData(data: any): void {
    this.currentData = data;
    this.cdr.detectChanges();
  }

  public onIntersectionChange(event: IntersectionState): void {
    if(event === IntersectionState.Inside) {
      this.isLoading = false;
    }
  }
}
