<app-section-layout
  [itemTemplate]="territoryGraph"
  [dataForChart]="territoryBarData"
></app-section-layout>

<ng-template
  #territoryGraph
  let-data="data"
  let-componentState="componentState"
  let-setComponentState="setComponentState"
>
  <app-territory-slide [data]="data" (selectedContinentalChange)="setComponentState($event)" [selectedContinental]="componentState"> </app-territory-slide>
</ng-template>
