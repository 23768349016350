import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { VerticalGraph } from 'src/app/model/vertical-views';

const CANVAS_HEIGHT_PX = 350;
const PADDING_BOTTOM_CANVAS_PX = 10;
const PADDING_TOP_CANVAS_PX = 100;
const COMPUTED_CANVAS_HEIGHT_PX = CANVAS_HEIGHT_PX - PADDING_BOTTOM_CANVAS_PX - PADDING_TOP_CANVAS_PX;
const MINCHANGE_PX_OFFSET = 32;

@Component({
  selector: 'app-line-bar-graph',
  templateUrl: './line-bar-graph.component.html',
  styleUrls: ['./line-bar-graph.component.scss']
})
export class LineBarGraphComponent implements OnInit, OnChanges {

  @Input() graph: VerticalGraph = {} as VerticalGraph;
  @Input() userData: any;
  @Input() isLoading: boolean = true;
  @Output() onUserBarSelect: EventEmitter<any> = new EventEmitter<any>();

  private chartInstance: any;
  private selectedColumnIndex: number = 0;
  public options: EChartsOption = {};

  private baseOptions: any = {
    textStyle: {
      color: '#768B95',
      fontSize: 10,
      fontFamily: 'InterRegular',
    },
    color: [
      new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#00567B' }, { offset: 1, color: '#1A4F69' },]),
    ],
    grid: {
      left: 18,
      right: 10,
      top: PADDING_TOP_CANVAS_PX,
      bottom: PADDING_BOTTOM_CANVAS_PX,
      containLabel: true,
    },
    graphic: [
      {
        elements: [
          {
            type: 'line',
            bottom: 39,
            zlevel: 1,
            shape: {
              x1: -10,
              y1: 0,
              x2: 10000,
              y2: 0
            },
            style: {
              stroke: '#294E67',
            },
          },
          {
            type: 'rect',
            bottom: 0,
            shape: {
              x: -10,
              width: 1000,
              height: 41
            },
            style: {
              fill: '#002947',
            }
          }
        ]
      }
    ],
    tooltip: {
      show: true,
      alwaysShowContent: true,
      trigger: 'axis',
      triggerOn: 'click',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      padding: 0,
      borderWidth: 0,
      confine: true,
      textStyle: {
        fontFamily: 'DemoLight',
        fontSize: 18,
        lineHeight: 23,
        color: '#D1DCDD',
      },
      formatter:
        `<div class="tooltip">
          <div class="col">
            <span class="title">{a0}</span>
            <span class="value kmt">{c0}</span>
          </div>
          <div class="saparator"></div>
          <div class="col">
            <span class="title">{a1}</span>
            <span class="value">{c1}</span>
          </div>
          <div class="saparator"></div>
          <div class="col">
            <span class="title">{a2}</span>
            <span class="value">{c2}</span>
          </div>
        </div>`
      ,
      position: function (pos: any) {
        return [pos[0] - 30, '5%'];
      },
      axisPointer: {
        type: 'none'
      },
      extraCssText: "box-shadow: 0 0 0 rgba(0, 0, 0, 0.0)",
    },
    dataset: {},
    xAxis: [
      {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#294E67',
          }
        },
        axisTick: {
          interval: 0,
          height: 6,
          color: '#1A4F69'
        },
        data: []
      },
      {
        id: 'colGroupName',
        type: 'category',
        axisLine: {
          show: false,
          onZero: false,
        },
        axisTick: {
          show: false,
          length: 12,
          lineStyle: {
            color: '#1A4F69'
          }
        },
        axisLabel: {
          margin: 0
        },
        position: 'bottom',
        offset: 25,
      },
    ],
    yAxis: {
      type: 'value',
      position: 'right',
      splitNumber: 3,
      axisLabel: {
        margin: 20,
        color: '#4E738B',
        fontSize: '11px',
        fontFamily: 'InterRegular',
        formatter: (value: number, index: number) => this.formatYAxisLabel(value, index)
      },
      splitLine: {
        lineStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#002949' }, { offset: 0.5, color: '#004A6A' }, { offset: 1, color: '#002949 ' }]),
        },
      },
    },
    series: [
      {
        id: 'KMT',
        name: 'KMT',
        type: 'bar',
        barWidth: 18,
        itemStyle: {
          shadowColor: 'rgba(0,33,59,0.6)',
          shadowBlur: 8,
        },
        selectedMode: true,
        select: {
          itemStyle: {
            borderWidth: 0,
            color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0, color: '#00DEEF' }, { offset: 1, color: '#1A4F69' }])
          }
        },
        xAxisIndex: 0,
        data: []
      },
      {
        id: 'FOB',
        name: 'FOB',
        type: 'line',
        symbol: 'circle',
        symbolSize: 8,
        selectedMode: true,
        itemStyle: {
          color: "#002947",
          borderWidth: 2,
          borderType: 'solid',
          borderColor: "#99B0BC",
          shadowBlur: 4,
          shadowColor: "#001E34",
          shadowOffsetX: 0,
          shadowOffsetY: 2
        },
        lineStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0.5, color: '#99B0BC' }]),
        },
        select: {
          itemStyle: {
            color: '#D1DCDD',
            borderColor:"#00567B",
          }
        },
        endLabel: {
          show: true,
          formatter: '{FOBKey|FOB}',
          rich: {
            FOBKey: {
              fontFamily: 'InterRegular',
              fontSize: 10,
              lineHeight: 12,
              color:'#99B0BC',
            },
          },
          offset: [6, 0],
        },
        xAxisIndex: 0,
        data: []
      },
      {
        id: 'CIF',
        name: 'CIF',
        type: 'line',
        symbol: 'circle',
        symbolSize: 8,
        selectedMode: true,
        itemStyle: {
          color: "#002947",
          borderWidth: 2,
          borderType: 'solid',
          borderColor: "#00567B",
          shadowBlur: 4,
          shadowColor: "#001E34",
          shadowOffsetX: 0,
          shadowOffsetY: 2
        },
        lineStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{ offset: 0.5, color: '#1A4F69' }]),
        },
        select: {
          itemStyle: {
            color: '#d1dcdd',
          }
        },
        endLabel: {
          show: true,
          formatter: '{CIFKey|CIF}',
          offset: [6, 0],
          rich: {
            CIFKey: {
              color: '#008CA4',
              fontFamily: 'InterRegular',
              fontSize: 10,
              lineHeight: 12,
            },
            FOBKey: {
              color:'#99B0BC',
              fontFamily: 'InterRegular',
              fontSize: 10,
              lineHeight: 12,
            },
          },
        },
        xAxisIndex: 0,
        data: []
      },
      {
        type: 'bar',
        xAxisIndex: 1,
        dimensions: ["colGroupName"]
      },
    ]
  }

  ngOnInit(): void {
    this.initGraphOption();
    this.selectDefaultColumn();
  }

  public onChartInit(ec: any): void {
    this.chartInstance = ec;
    this.initChartEvents(ec);
  }

  private initGraphOption(): void {
    this.baseOptions.xAxis[0].data = this.graph.data.map(item => item.colName);
    this.baseOptions.xAxis[1].axisTick.show = !this.graph.data.every(item => item.colGroupName === this.graph.data[0].colGroupName);
    this.baseOptions.dataset = {
      source: [...this.graph.data]
    };
    this.baseOptions.series[0].data = [...this.graph.data.map(item => ({ value: item.current?.value ? item.current?.value : '-', name: item.colGroupName + "-" + item.colName }))]
    this.baseOptions.series[1].data = [...this.graph.data.map(item => ({ value: item.fob ? item.fob : '-', name: item.colGroupName + "-" + item.colName }))]
    this.baseOptions.series[2].data = [...this.graph.data.map(item => ({ value: item.cif ? item.cif : '-', name: item.colGroupName + "-" + item.colName }))]
    const lastValueFOB = this.baseOptions.series[1].data[this.baseOptions.series[1].data.length - 1]?.value;
    const lastValueCIF = this.baseOptions.series[2].data[this.baseOptions.series[2].data.length - 1]?.value;
    // Offsets Section
    let requireOffset = false;
    if(lastValueFOB && lastValueCIF) {
      if(lastValueFOB === lastValueCIF) {
        requireOffset = true;
      }else {
        requireOffset = this.shouldOffset(lastValueCIF, lastValueFOB);
      }
    }
    if(requireOffset) {
          this.baseOptions.series[1].endLabel.show = false;
          this.baseOptions.series[2].endLabel.formatter = this.getOffsetLabel(lastValueCIF, lastValueFOB);
    }
    this.options = { ...this.baseOptions };
  }

    private calculatePxRatio(): number {
    let valuesArray: number[] = [];
    this.graph.data.forEach((item => {
      if(item.current) {
        valuesArray.push(Math.abs(item.current.value));
      }
      if(item.cif) {
        valuesArray.push(Math.abs(item.cif));
      }
      if(item.fob) {
        valuesArray.push(Math.abs(item.fob));
      }
    }));
    const maxValue = Math.max(...valuesArray);
    const pxRatio = COMPUTED_CANVAS_HEIGHT_PX / maxValue;
    return pxRatio;
  }

  private convertValueToPx(pxRatio: number, value: number): number {
    return value * pxRatio
  }

  private shouldOffset(value1: number, value2: number): boolean {
    let shouldOffset = false;
    const pxRatio = this.calculatePxRatio();
    const pxValue1 = this.convertValueToPx(pxRatio, value1);
    const pxValue2 = this.convertValueToPx(pxRatio, value2);
    const distance = Math.abs(pxValue1 - pxValue2);

    const minChange = MINCHANGE_PX_OFFSET;
    if(distance <= minChange) {
      shouldOffset = true;
    }
    return shouldOffset;
  }

  private getOffsetLabel(CIFValue: number, FOBValue: number) {
    const [higherValue, lowerValue] = CIFValue >= FOBValue ? ['CIF', 'FOB'] : ['FOB', 'CIF'];
    const [higherKey, lowerKey] = CIFValue >= FOBValue ? ['CIFKey', 'FOBKey'] : ['FOBKey', 'CIFKey'];

    return `{${higherKey}|${higherValue}}\n{${lowerKey}|${lowerValue}}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['userData'] && this.userData) {
      const dataIndex = this.graph.data.findIndex(item => item.colName === this.userData.colName);
      if(dataIndex > -1) {
        this.selectedColumnIndex = dataIndex;
        this.selectColumn(dataIndex);
      }
    }

    if(changes['isLoading'] && !this.isLoading) {
      this.selectDefaultColumn();
    }

    if(changes['graph'] && this.graph) {
      this.initGraphOption();
      if(!this.userData) {
        this.selectDefaultColumn();
      } else {
        const dataIndex = this.graph.data.findIndex(item => item.colName === this.userData.colName);
        if(dataIndex > -1) {
          this.selectColumn(dataIndex);
        } else {
          this.selectDefaultColumn();
        }
      }
    }
  }

  private formatYAxisLabel(value: number, index: number): string {
    return value !== 0 && index !== 0 ? `${value} ${this.graph.columnType ? this.graph.columnType : ''}` : "";
  }

  private initChartEvents(chartInstance: any): void {
    chartInstance.on('click', 'series', (params: any) => {
      this.selectColumn(params.dataIndex);
    });

    chartInstance.getZr().on('click', (params: any) => {
      let pointInPixel = [params.offsetX, params.offsetY];
      let pointInGrid = chartInstance.convertFromPixel('grid', pointInPixel);
      let index = pointInGrid[0];

      if (index > -1 && index < this.graph.data.length) {
        this.selectColumn(index);
        this.onUserBarSelect.emit(this.graph.data[index]);
      }
    });
  }

  private selectColumn(dataIndex: number): void {
    setTimeout(() => {
      this.chartInstance?.dispatchAction({ type: 'select', dataIndex: dataIndex });
      this.chartInstance?.dispatchAction({ type: 'showTip', seriesIndex: 1, dataIndex: dataIndex });
      this.selectedColumnIndex = dataIndex;
    });
  }

  private selectDefaultColumn():void {
    setTimeout(() => {
      let defaultIndex = this.graph.data.findIndex(item => item.isDefault);
      this.selectColumn(defaultIndex);
    });
  }
}
