<div class="splash-container">
  <lottie-player 
  preserveAspectRatio="xMidYMin slice" 
  class="splash-lotti" 
  src="../../assets/animations/ICL - splash 3.json" 
  autoplay >
  </lottie-player>
  <div class="bottom-container">
    <img
    src="assets/images/splash-button.png"
    (click)="launch()"
      class="launch-button"
      alt="splash-button"
      />
      <div class="version">Version: {{ version }}</div>
    </div>
  </div>