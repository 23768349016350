import { Component, Input } from '@angular/core';
import { ComSpecBlockType, ComSpecTable } from 'src/app/model/commodities-graph';

@Component({
  selector: 'app-financial-data-table',
  templateUrl: './financial-data-table.component.html',
  styleUrls: ['./financial-data-table.component.scss']
})
export class FinancialDataTableComponent {
  @Input() data: ComSpecTable = {} as ComSpecTable;
  @Input() isCompare: boolean = false;
  @Input() selectedType: number = 0;
  @Input() isPercentage: boolean = false;

  public blockTypes: any = ComSpecBlockType;

  public formatRowNames(title:String):any {
    if(this.isPercentage && title.endsWith(' %')){
      return title.replace(' %','')
    }
    return title
  }
}
