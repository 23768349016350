import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NavigationItem, RTSparkChart, SubNavigationItem } from 'src/app/model/production-views';
import { VerticalGraph } from 'src/app/model/vertical-views';

@Component({
  selector: 'app-business-unit-navigation',
  templateUrl: './business-unit-navigation.component.html',
  styleUrls: ['./business-unit-navigation.component.scss']
})
export class BusinessUnitNavigationComponent implements AfterViewInit {
  @Input() businessUnits: NavigationItem<RTSparkChart>[] = [];
  @Input() subBusinessUnits: SubNavigationItem<RTSparkChart>[] = [];
  @Input() currentUnitIndex: number = 0;
  @Input() currentSubUnitIndex: number = 0;
  @Output() currentUnitIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() currentSubUnitIndexChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('businessUnitsDiv') businessUnitsDiv!: ElementRef;

  constructor() { }
  ngAfterViewInit(): void {
    if(this.currentUnitIndex !== 0) {
      const element = this.businessUnitsDiv.nativeElement;
      if(element.children[this.currentUnitIndex]){
        const childElement = element.children[this.currentUnitIndex];
        element.scrollLeft = childElement.offsetLeft;
      }
    }
  }

  public changeUnit(unitIndex: number): void {
    this.currentUnitIndex = unitIndex;
    this.currentUnitIndexChange.emit(this.currentUnitIndex);
    if(this.subBusinessUnits.length > 0){
      this.changeSubUnit(0);
    }
  }
  public changeSubUnit(subUnitIndex: number): void {
    this.currentSubUnitIndex = subUnitIndex;
    this.currentSubUnitIndexChange.emit(this.currentSubUnitIndex);
  }


}
