import { Component, Input, OnInit } from '@angular/core';
import { EPSDataItem } from 'src/app/model/vertical-views';

@Component({
  selector: 'app-eps-footer',
  templateUrl: './eps-footer.component.html',
  styleUrls: ['./eps-footer.component.scss']
})
export class EpsFooterComponent implements OnInit {

  @Input() epsFooterData!: EPSDataItem;

  ngOnInit(): void { }

}
