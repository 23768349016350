import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { NavigationOptions } from 'src/app/model/queryParams';
import { SBUGraphView } from 'src/app/model/sbu-grpah';

@Component({
  selector: 'app-sbu-slide',
  templateUrl: './sbu-slide.component.html',
  styleUrls: ['./sbu-slide.component.scss']
})
export class SbuSlideComponent{
  /**
   * @property {NavigationOptions} navState contains the navigtaion info for the currently displyed graph (time tab, slide, dropdown and graph name)
   */
  @Input() sbuGraph:SBUGraphView = {} as SBUGraphView;
  @Input() isMainGraph: boolean = true;
  @Input() dropdownGraphTitle: String = '';
  @Input() navState: NavigationOptions = {};
  @Input() isLoading: boolean = true;

  constructor(private router: Router){};

  public onNavigateToGraph(divTitle: String, bu: String): void {    
    if(!this.isMainGraph) {
      return;
    }

    this.router.navigate([], {
      queryParams: {
        div: divTitle,
        graph: this.navState.dropdown,
        nav: this.navState.nav,
        slide: this.navState.slide,
        bu: bu
      }
    });
  }
}
