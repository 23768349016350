import { Component } from '@angular/core';

@Component({
  selector: 'app-post-authorization',
  templateUrl: './post-authorization.component.html',
  styleUrls: ['./post-authorization.component.scss']
})
export class PostAuthorizationComponent {

}
