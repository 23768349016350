import { Observable, Subject, distinctUntilChanged, fromEvent, map, pairwise, takeUntil, throttleTime } from 'rxjs';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { GraphContainer } from 'src/app/model/generic-graph';
import { LoadableState } from 'src/app/model/loadable-state';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnDestroy, OnInit {
  private prevDivision: string = '';
  private _destroyed$ = new Subject();
  
  public stateData$!: Observable<LoadableState<GraphContainer[]> | null>;
  public timeoutId: any = 0;
  public hideGraphNav: boolean = false;
  
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.stateData$ = this.dataService.data$;
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe((params) => {
      if (this.prevDivision !== params.div) {
        this.prevDivision = params.div;
        this.dataService.setDataByDivision(params.div);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }

  public getAnchors(data: GraphContainer[]): string[] {
    return data.map(graph => graph.title as string);
  }
}
