<header>
    <nav>
        <ul>
            <li *ngFor="let division of divisions" #divisions>
                <button class="division"
                    [ngClass]="{'active': division === currentDivision}"
                    (click)="activateDivision($event, division)">
                    {{division}}
                </button>
            </li>
        </ul>
        <div class="active-division-indicator" [ngStyle]="activeDivisionIndicatorStyles"></div>
    </nav>
</header>