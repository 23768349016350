
<app-section-layout [itemTemplate]="dataTableAndMultiBarChartEl" [dataForChart]="graphData" (onIntersection)="onIntersectionChange($event)" ></app-section-layout>
<ng-template #dataTableAndMultiBarChartEl let-data="data">
<div class="mitter-charts-container">
    <app-half-doughunt-graph chartId="meetingHalfDoughnutChartId" [data]="data.graph.objectives" [isLoading]="isLoading" ></app-half-doughunt-graph>
    <app-half-doughunt-graph chartId="salseHalfDoughnutChartId" [data]="data.graph.formula" [isLoading]="isLoading" ></app-half-doughunt-graph>
</div>
<div class="saparator"></div>
<div class="data-table-multi-charts-container">
    <div class="content-header">
        <div class="titles">
            <span></span>
            <span class="ACT">ACT</span>
            <span class="TGRT">TRGT</span>
        </div>
        <div class="header-table">
        </div>
    </div>
    <div *ngFor="let row of data.graph.data" class="table-data-container">
    <div class="content">
        <div class="title-table">
            <span class="title">{{''+row.columns[0]+'%'}}</span>
            <span class="description">{{row.title}}</span>
        </div>
        <span class="data-table">{{row.columns[1]}}</span>
        <span class="data-table">{{row.columns[2]}}</span>
    </div>
        <app-multi-bar-chart symbolForBar="%" [value]="row.columns[3]"></app-multi-bar-chart>
    </div>
</div>
</ng-template>
