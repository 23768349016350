<div class="footer">
    <div>
        <div class="footerCell" *ngIf="epsFooterData">
            <span class="title">Diluted Shares (#)</span>
            <span class="text color-text" *ngIf="epsFooterData.dilutedShares" >{{epsFooterData.dilutedShares | number:'1.0-2'}}</span>
        </div>
        <div class="footerCell" *ngIf="epsFooterData && epsFooterData.dilutedSharesCompare">
            <span class="text">{{epsFooterData.dilutedSharesCompare | number:'1.0-2'}}</span>
        </div>
    </div>
    <div class="line"></div>
    <div>
        <div class="footerCell" *ngIf="epsFooterData">
            <span class="title">Adjusted N.I (M$)</span>
            <span class="text color-text" *ngIf="epsFooterData.adjustedNI">{{epsFooterData.adjustedNI | number:'1.0-2'}}</span>
        </div>
        <div class="footerCell" *ngIf="epsFooterData && epsFooterData.adjustedNICompare">
            <span class="text">{{epsFooterData.adjustedNICompare | number:'1.0-2'}}</span>
        </div>
    </div>
</div>
