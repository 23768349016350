<app-section-layout [dataForChart]="graphData" [itemTemplate]="horizontalGraph" (onIntersection)="onIntersectionChange($event)" ></app-section-layout>

<ng-template
 #horizontalGraph
 let-data="data"
 let-componentState="componentState"
 let-setComponentState="setComponentState"
 
>
    <app-horizontal-bar-graph 
        [withGestures]="withGestures"
        [graph]="data.graph" 
        [selectedItem]="componentState" 
        (onSelectItem)="setComponentState($event)"
        [isLoading]="isLoading"
        >
    </app-horizontal-bar-graph>
</ng-template>