import { Graph } from "./generic-graph";
import { VerticalGraph } from "./vertical-views";


export interface HorizontalGraphView extends Graph {
  data: HorizontalItemRow[];
}

export interface HorizontalItemRow {
  title: String;
  value: HorizontalItemRowAmount;
  kmt: HorizontalItemRowAmount;
  price: HorizontalItemRowAmount;
  data?: HorizontalItemRowData;
}

interface HorizontalItemRowAmount {
  value: number;
  positive: number; //-1 0 1
}

export interface HorizontalItemRowData {
  fields: HorizontalItemRowDataField[]; //amount of items needs to match columns in graphs. all graphs MUST have the same amount of columns
  graphs: HorizontalItemRowDataGraphs;
}
export enum HorizontalBarGraphUnit {
  KMT = 'kmt',
  DOLLARMT = 'dollarMT',
  MDOLLAR = 'mDollar'
}

//new
export interface HorizontalItemRowDataGraphs {
  [HorizontalBarGraphUnit.KMT] : VerticalGraph;
  [HorizontalBarGraphUnit.DOLLARMT]: VerticalGraph;
  [HorizontalBarGraphUnit.MDOLLAR]: VerticalGraph;
}


//new (previously was HorizontalItemRowData)
export interface HorizontalItemRowDataField {
  kmt: HorizontalItemRowDataItem;
  dollarMT: HorizontalItemRowDataItem;
  mDollar: HorizontalItemRowDataItem;
}

interface HorizontalItemRowDataItem {
  sales: number; 
  salesCompare?: number; 
  positive: number //-1 0 1
}

export enum SalesUnits {
  kmt = 'KMT',
  dollarMT = '$/MT',
  mDollar = 'M$',
}
