<div class="kmt-cell" [ngClass]=" cellDetails.compareValue ? 'cell'+ (cellNumber).toString() + '-compare' : 'cell'+ (cellNumber).toString()" (click)="selectCell(cellDetails)">
    <span class="title">{{cellDetails.title}}</span>
    <div *ngIf="cellDetails.compareValue"  class="compare-subtitle-value" [ngClass]="{'reverse' : cellDetails.reverseView}" >
        <div class="compare-col">
            <span class="compare-subtitle">{{cellDetails.compareSubtitle}}</span>
            <span class="compare-value">{{cellDetails.compareValue}}</span>
        </div>
        <div class="current-col">
            <span class="current-value-subtitle">{{cellDetails.valueSubtitle}}</span>
            <span class="current-value" >
                <app-number-counter [shouldPlay]="shouldAnimate" [countValue]="cellDetails.value" [duration]="duration"></app-number-counter>
            </span>
        </div>
    </div>

    <div *ngIf="!cellDetails.compareValue" class="value-container">
        <div class="value">
            <app-number-counter [shouldPlay]="shouldAnimate" [countValue]="cellDetails.value" [duration]="duration"></app-number-counter>
        </div>
    </div>
    <div *ngIf="cellDetails.delta !== null"  class="compare" [ngClass]="isAboveDeltaThreshold(cellDetails.deltaPercent, cellDetails.delta) ? 'above' : 'under'">

        <span *ngIf=" cellNumber > 2"
        class="mark-rate"
        [ngClass]="{
            'down-gesture': cellDetails.deltaPercent < 0,
            'up-gesture': cellDetails.deltaPercent >= 0
          }"
        >&nbsp;</span>
        <span class="legendValue">{{cellDetails.delta}}</span>
        <span class="deltaPercent">&nbsp;{{ '(' +(cellDetails.deltaPercent | handleValueSignForMultiBar) + '%)'}}&nbsp;</span>
        <span *ngIf="cellNumber < 3">
            {{ isAboveDeltaThreshold(cellDetails.deltaPercent, cellDetails.delta) ? 'Above' : 'Under' }}
        </span>
        <span class="legendValue" *ngIf="cellNumber < 3">&nbsp;{{cellDetails.legendValue}}</span>
    </div>
</div>
