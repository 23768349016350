<h2 class="section-title">Real Time Production</h2>
<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="graphData[selectedBUIndex].data as data">
        <app-spark-chart *ngIf="data[selectedSBUIndex].data" 
        [data]="data[selectedSBUIndex].data"></app-spark-chart>
    </ng-container>
</ng-container>
<ng-container *ngIf="isLoading">
    <div class="chart-container">
        <app-loader></app-loader>
    </div>
</ng-container>
