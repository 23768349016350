import { Component, Input } from '@angular/core';
import { GraphContainer, GraphView } from 'src/app/model/generic-graph';
import {
  VerticalGraphItem,
  VerticalGraphType,
} from 'src/app/model/vertical-views';
import { IntersectionState } from '../section-layout/section-layout.component';

@Component({
  selector: 'app-net-income',
  templateUrl: './net-income.component.html',
  styleUrls: ['./net-income.component.scss'],
})
export class NetIncomeComponent {
  @Input() graphData!: GraphContainer;
  public isLoading: boolean = true;

  public verticalGraphTypes: any = VerticalGraphType;

  constructor() {}

  public onSelectGarphValue(graphItem: VerticalGraphItem): void {}

  public getBarType(data: GraphView): VerticalGraphType {
    if (data.title === 'LY' || data.title === 'BGT') {
      return VerticalGraphType.COMPARE_BAR;
    } else {
      return VerticalGraphType.SIMPLE_BAR;
    }
  }

  public onIntersectionChange(event: IntersectionState): void {
    if(event === IntersectionState.Inside) {
      this.isLoading = false;
    }
  }
}
