import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComSpecBlockType, PieGraphItem } from 'src/app/model/commodities-graph';

/**
 * @description
 * This component displays a compare view of business groups' (commodities and specialties) activity.
 */
@Component({
  selector: 'app-comm-spec-compare-graph',
  templateUrl: './comm-spec-compare-graph.component.html',
  styleUrls: ['./comm-spec-compare-graph.component.scss']
})
export class CommSpecCompareGraphComponent {
  @Input() data: PieGraphItem[] = [];
  @Input() selectedType: number = 0;
  @Output() onBarClick: EventEmitter<PieGraphItem> = new EventEmitter<PieGraphItem>();

  public commoditiesIndex: number = 0;
  public specialtiesIndex: number = 1;
  public types: any = ComSpecBlockType;

  ngOnInit(): void {
   this.initIndexes();
  }

  private initIndexes(): void {
    this.data.map((item:PieGraphItem, index: number) => {
      if(item.title.toLowerCase() === this.types[0]) {
        this.commoditiesIndex = index;
      }

      if(item.title.toLowerCase() === this.types[1]) {
        this.specialtiesIndex = index;
      }
    });
  }

  public selectBusinessGroup(businessGroup: PieGraphItem): void {
    this.onBarClick.emit(businessGroup);
  }
}
