<app-vertical-bar-graph 
    [graph]="data.graph.data" 
    [selectedColumnIndex]="currentSelectedColumnIndex"
    (onBarSelect)="onSelectGarphValue($event)" 
    (onUserBarSelect)="onUserBarSelect($event)" 
    [type]="getBarType(data)"
    [isLoading]="isLoading"
    >
</app-vertical-bar-graph>

<app-eps-footer 
    [epsFooterData]="footerData">
</app-eps-footer>
