import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent {
  constructor(
    private msalService: MsalService,
    private authorizationService: AuthorizationService,
    private router: Router
  ) {}
  private intervalId: any;
  private _destroyed$ = new Subject();
  private counter = 10;
  ngOnInit() {
    this.intervalId = setInterval(()=>{
      if (this.counter === 0) {
        this.router.navigate(['/'])
      }
      if (
        this.authorizationService.isNative() ||
        this.msalService.instance.getAllAccounts().length > 0
      ) {
        this.router.navigate(['/dashboard']);
      }else{
        this.counter = this.counter -1
      }
    },500)
  }
  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
