<app-section-layout 
[dataForChart]="graphData" 
[itemTemplate]="productionDivisionGraph" 
(updateNavState)="updateNavState($event)"
(onIntersection)="onIntersectionChange($event)"
></app-section-layout>

<ng-template 
 #productionDivisionGraph 
 let-data="data" 
 let-navInfo="navInfo" 
 let-componentState="componentState"
 let-setComponentState="setComponentState"
>
    <app-production-division-slide
     [data]="data"
     [navInfo]="navInfo" 
     (onUserSelectedData)="setComponentState($event)"
     [currentData]="componentState"
     [currentBUIndex]="currentBuIndex"
     [currentSBUIndex]="currentSbuIndex"
     [isLoading]="isLoading"
     class="app-production-division"
    ></app-production-division-slide>
</ng-template>

<app-real-time-production 
 *ngIf="graphData.subMenu" 
 [graphData]="graphData.subMenu" 
 [selectedBUIndex]="currentBuIndex" 
 [selectedSBUIndex]="currentSbuIndex"
 [isLoading]="isLoading"
 class="app-real-time-production"
></app-real-time-production>