import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { LoadableState } from '../model/loadable-state';

/**
 * @description
 * This service is used to handle the state of a HTTP request.
 * It starts every http request with a 'loading' state, until it gets a response from the service.
 * According to the response it will send the data in case of a sucessful request.
 * Otherwise, it will return a detailed 'error' state.
 */
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient) { }

  public get<T>(url: string): Observable<LoadableState<T>> {
    return this.httpClient.get<T>(url).pipe(
      map(data => ({ isLoading: false, data })),
      catchError(error => of({ isLoading: false, error })),
      startWith({ isLoading: true }));
  }

  public getDirect<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url);
  }
}
