import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  IStockChartData,
  IStocksData,
  IstocksDataOrigin,
} from '../model/stocks-panel';


/**
 * @description This service control the data of stocks.
 */
@Injectable({
  providedIn: 'root',
})
export class StockDataService {
  private stocksDataSubject: BehaviorSubject<IStocksData[] | null> =
    new BehaviorSubject<IStocksData[] | null>(null);

  public normalizedStocksData$: Observable<IStocksData[] | null> =
    this.stocksDataSubject.asObservable();

  private stockChartDataSubject: BehaviorSubject<IStockChartData | null> =
    new BehaviorSubject<IStockChartData | null>(null);

  public stockChartData$: Observable<IStockChartData | null> =
    this.stockChartDataSubject.asObservable();

  constructor(
    private httpClient: HttpClient
  ) {}

  /**
   * @description format date into '+/- HH:MM AM/PM'
   * @param {Date} date
   * @returns {string} date in this format'+/- HH:MM AM/PM'
   */
  public getTime(date: Date) : string {
    let AMPM = '';
    let hours;
    if(date.getHours() < 10){
      hours = date.getHours();
      AMPM = 'AM';
    } else{
      hours = date.getHours() - 12;
      AMPM = 'PM';
    }
    const minutes =
      date.getMinutes() < 10
        ? '0' + date.getMinutes()
        : date.getMinutes().toString();
    return `${hours}:${minutes}${AMPM}`;
  }

  /**
   * @description get summarize of stocks information.
   */
  public getStocks() : void {
    const url = environment.baseUri + environment.stocksAPIUrl;
    this.httpClient
      .get(url)
      .pipe(
        map((stockArr: any) => {
          const newStockArr: IStocksData[] = stockArr.map(
            (stock: IstocksDataOrigin) => {
              return {
                stockId: stock.stockId,
                exchange: stock.exchange,
                stock: stock.stock,
                rate: +stock.LastKnownRate,
                dayChange: +stock.BaseRateChangePercentage > 0 ? `+${stock.BaseRateChangePercentage}` : +stock.BaseRateChangePercentage,
                time: this.getTime(new Date(stock.LastKnownRateDate)),
              };
            }
          );
          return newStockArr;
        })
      )
      .subscribe((data) => this.stocksDataSubject.next(data));
  }

  /**
   * @description get detailed stock information for a spesific stock.
   * @param {number} stockId - selected stock id.
   * @returns {Observable<IStockChartData | null>} - detailed stock information for line graph.
   */
  public setStockOnChart(stockId: number): Observable<IStockChartData | null> {
    if (stockId) {
      const url = (
        environment.baseUri + environment.histogramStockAPIUrl
      ).replace('{{paperId}}', stockId.toString());
      this.httpClient
        .get<IStockChartData>(url)
        .pipe(
          catchError((err) => {
            return [];
          })
        )
        .subscribe((data: IStockChartData) => {
          this.stockChartDataSubject.next(data);
        });
    }
    return this.stockChartData$;
  }
}
