import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Animations } from 'src/app/animations';
import { HorizontalBarGraphUnit, HorizontalItemRow } from 'src/app/model/horizontal-graph';
import { VerticalGraphItem } from 'src/app/model/vertical-views';
import { ISelectedItem } from '../horizontal-bar-graph/horizontal-bar-graph.component';

@Component({
  selector: 'app-horizontal-bar',
  templateUrl: './horizontal-bar.component.html',
  styleUrls: ['./horizontal-bar.component.scss'],
  animations: [
    Animations.openClose
  ]
})
export class HorizontalBarComponent {
  @Input() maxValue: number = 1;
  @Input() selected: boolean = false;
  @Input() withGestures: boolean = true;
  @Input() item: HorizontalItemRow = {} as HorizontalItemRow;
  @Input() selectedItem?: ISelectedItem | null;
  @Output() onSelectTitle: EventEmitter<String> = new EventEmitter<String>()
  @Output() onUpdateItem: EventEmitter<ISelectedItem> = new EventEmitter<ISelectedItem>()
  @Input() isLoading: boolean = true;
  public width: number = 1;

  constructor() { }


  ngOnChanges(): void {
    setTimeout(() => {
      this.width = (this.item.value.value / this.maxValue) * 100;
    }, 0);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.width = (this.item.value.value / this.maxValue) * 100;
    }, 0);
  }

  selectTitle(title: String): void {
    this.onSelectTitle.emit(title);
  }

  openMoreInfo(isMoreInfo: boolean): void {
    this.updateCurrentItem({ isMoreInfo });
  }
  
  selectUnit(unit: HorizontalBarGraphUnit): void {
    this.updateCurrentItem({ unit });
  }
  
  selectColumn(column: VerticalGraphItem): void {
    this.updateCurrentItem({ column });
  }

  updateCurrentItem(update: Partial<ISelectedItem>): void {
    this.onUpdateItem.emit({...this.selectedItem, ...update})
  }
  
}
