import { Graph } from "./generic-graph";
import { VerticalGraph } from "./vertical-views";

/**
 * atomic graph item for commodities etc.
 */
export interface PieGraph {
  data: PieGraphItem[];
  isCompare: boolean;
}

export interface PieGraphItem {
  title: String;
  value: number;
  percent: number;
  valueCompare: number; // not used in ui
  percentCompare: number;
  isDefault: boolean;
}

export interface ComSpecTable {
  rowNames: String[];
  blocks: ComSpecBlock[];
}
interface ComSpecBlock {
  title: String;
  fields: number[];
  type: string;
}

export enum ComSpecBlockType {
  'commodities',
  'specialties',
  'other'
}

export interface ComSpecGraphView extends Graph {
  pieGraphs: PieGraph[];
  graphs: VerticalGraph[];
  blocks: ComSpecTable[];
  defaultPieGraphIndex: number;
}
