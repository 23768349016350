import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-multi-bar-chart',
  templateUrl: './multi-bar-chart.component.html',
  styleUrls: ['./multi-bar-chart.component.scss']
})
export class MultiBarChartComponent {
  @Input() value: number = 0;
  @Input() symbolForBar: string = '';
  constructor() { }
  
  public getChangeWith(value: number) : number {
    return value > 100 ? 80 : Math.abs(value*0.8);
  }
}
