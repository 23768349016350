import { Component, Input } from '@angular/core';
import { GraphContainer } from 'src/app/model/generic-graph';
import { NavigationOptions } from 'src/app/model/queryParams';
import { IntersectionState } from '../section-layout/section-layout.component';
import { BehaviorSubject, Observable } from 'rxjs';


@Component({
  selector: 'app-production-kmt',
  templateUrl: './production-kmt.component.html',
  styleUrls: ['./production-kmt.component.scss']
})
export class ProductionKmtComponent {
  @Input() graphData!: GraphContainer;
  @Input() endValue = 100;
  intersectionSubject: BehaviorSubject<IntersectionState> = new BehaviorSubject<IntersectionState>(IntersectionState.BeforeEnter);
  intersection$:Observable<IntersectionState> = this.intersectionSubject.asObservable(); 
  isInViewport = false;

  onIntersection(event: IntersectionState) {
    this.intersectionSubject.next(event)
  }
}
