<app-divisions-header></app-divisions-header>
    
<div #yourElement>
    <ng-container *ngIf="stateData$ | async as stateData" >
        <ng-container *ngIf="stateData.isLoading">&nbsp;</ng-container>
        
        <ng-container *ngIf="stateData.data as data">
            <app-graphs-anchors *ngIf="data.length > 1" [graphs]="getAnchors(data)" class="anchors" [ngClass]="{'hidden': hideGraphNav}"></app-graphs-anchors>
            
            <app-stocks-panel></app-stocks-panel>
    
            <ng-container *ngFor="let graph of data">
                <app-switch-graphs [graph]="graph"></app-switch-graphs>
            </ng-container>
        </ng-container>
    </ng-container>

</div>
