import { Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductionFinVizView } from 'src/app/model/production-views';
import { NavigationOptions } from 'src/app/model/queryParams';
import { IntersectionState } from '../section-layout/section-layout.component';

@Component({
  selector: 'app-production-kmt-slide',
  templateUrl: './production-kmt-slide.component.html',
  styleUrls: ['./production-kmt-slide.component.scss']
})
export class ProductionKmtSlideComponent implements OnInit {
  @Input() productionKpiGraph:ProductionFinVizView[] = [];
  @Input() navState: NavigationOptions = {};
  @Input() intersection!: Observable<IntersectionState>;
  shouldAnimate = false;

  constructor(private router: Router){}

  ngOnInit(): void {
      this.intersection.subscribe((state:IntersectionState) => {
        if(state === IntersectionState.Entering) {
          this.shouldAnimate = true;
        }
      })
  }

  public navigateToGraph(cellDetails: any){
    const navOptions: NavigationOptions = {
      nav: this.navState.nav,
      div: cellDetails.division,
      bu: cellDetails.businessUnit ?? undefined,
      sbu: cellDetails.subBusinessUnit ?? undefined,
      buSubtitle: cellDetails.businessUnitSubtitle ?? undefined,
      slide: this.navState.slide,
      graph: this.navState.graph,
    }
    this.router.navigate([], {
      queryParams: navOptions,
    })
  }
}
