<app-section-layout [itemTemplate]="dataTableAndMultiBarChartEl" [dataForChart]="graphData"></app-section-layout>
<ng-template #dataTableAndMultiBarChartEl let-data="data">
    <div class="data-table-multi-charts-container">
        <div *ngFor="let item of data.graph.data" class="table-data-container">
            <div class="content">
                <div class="title-table">
                    <span class="title">{{item.title}}</span>
                </div>
            </div>
            <app-multi-bar-chart [value]="item.value" marker=""></app-multi-bar-chart>
        </div>
    </div>
</ng-template>