import { PieGraphItem } from 'src/app/model/commodities-graph';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-pi-graph',
  templateUrl: './pi-graph.component.html',
  styleUrls: ['./pi-graph.component.scss'],
})
export class PiGraphComponent {
  @Input() data: PieGraphItem[] = [];
  @Input() isLoading: boolean  = true;
  @Output() onPieClick: EventEmitter<PieGraphItem> =
    new EventEmitter<PieGraphItem>();

  public myChart: any;
  @Input() selectedIndex: number = 0;
  public mergeOptions: echarts.EChartsOption = {};

  public colors: { [key: string]: { font: string; background: any } } = {
    specialties: {
      font: '#038BE5',
      background: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
        { offset: 0, color: '#0093EF' },
        { offset: 1, color: '#1E478D' },
      ]),
    },
    commodities: {
      font: '#00BADA',
      background: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
        { offset: 0, color: '#00DEEF' },
        { offset: 1, color: '#1A4F69' },
      ]),
    },
  };

  public basicOptions: any = {
    textStyle: {
      fontFamily: 'DemoLight',
    },
    color: [
      new echarts.graphic.LinearGradient(0, 0, 1, 1, [
        { offset: 0, color: '#00567B' },
        { offset: 1, color: '#004764' },
      ]),
    ],
    dataset: {
      source: [],
    },
    series: [
      {
        name: '',
        type: 'pie',
        dimensions: [],
        radius: [18, 40],
        center: ['50%', '51%'],
        itemStyle: {
          borderWidth: 3,
          borderColor: '#002947',
        },
        label: {
          color: '#D1DCDD4D',
          fontWeight: 300,
          fontFamily: 'DemoLight',
          lineHeight: 27,
          formatter: (params: any): string => {
            return `{percent|${params.data.percent}% } {value|${params.data.value}\n}{name|${params.name}}`;
          },
          rich: {
            percent: {
              align: 'left',
              fontSize: 18,
              verticalAlign: 'bottom',
            },
            value: {
              align: 'left',
              fontSize: 15,
              verticalAlign: 'bottom',
            },
            name: {
              align: 'left',
              fontSize: 13,
              lineHeight: 18,
            },
          },
        },
        labelLine: {
          show: true,
          lineStyle: {
            color: '#294E67',
          },
        },
        emphasis: {
          scale: false,
          label: {
            rich: {
              name: {
                color: '#A8BDC7',
              },
            },
          },
        },
      },
    ],
  };

  public options: any = {};

  ngOnInit(): void {
    this.initGraphOptions();
  }

  public initGraphOptions(): void {
    this.basicOptions.dataset.source = this.data.map((item) => ({
      ...item,
      emphasis: {
        itemStyle: {
          color: this.colors[item.title.toLowerCase()].background,
        },
        label: {
          rich: {
            percent: {
              color: this.colors[item.title.toLowerCase()].font,
            },
            value: {
              color: this.colors[item.title.toLowerCase()].font,
            },
          },
        },
      },
    }));
    this.basicOptions.series[0].dimensions = Object.keys(this.data[0]);
    this.options = { ...this.basicOptions };
  }

  public onChartInit(ec: any): void {
    this.myChart = ec;
    this.initChartEvents(ec);
  }

  private initChartEvents(chartInstance: any): void {
    // const defaultIndex = this.data.findIndex(
    //   (item) => item.title.toLowerCase() === 'commodities'
    // );
    this.selectItem(this.selectedIndex);

    chartInstance.on('mouseup', 'series', (params: any) => {
      if (this.selectedIndex !== params.dataIndex) {
        this.selectItem(params.dataIndex);
        this.onPieClick.emit(params.data);
      }
    });
  }

  private selectItem(index: number): void {
    setTimeout(() => {
        this.myChart?.dispatchAction({
          type: 'downplay',
        });
        this.selectedIndex = index;
        this.myChart?.dispatchAction({ type: 'highlight', dataIndex: this.selectedIndex });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['selectedIndex'] && this.selectedIndex !== null && this.selectedIndex !== undefined ) {
      this.selectItem(this.selectedIndex);
    }


    if (changes['data']) {
      this.mergeOptions = {
        dataset: {
          source: changes['data'].currentValue.map((item: any) => ({
            ...item,
            emphasis: {
              itemStyle: {
                color: this.colors[item.title.toLowerCase()].background,
              },
              label: {
                rich: {
                  percent: {
                    color: this.colors[item.title.toLowerCase()].font,
                  },
                  value: {
                    color: this.colors[item.title.toLowerCase()].font,
                  },
                },
              },
            },
          })),
        },
      };
    }
  }
}
