import { ChangeDetectorRef, Component, Input} from '@angular/core';
import { GraphContainer } from 'src/app/model/generic-graph';
import { NavigationOptions } from 'src/app/model/queryParams';
import { IntersectionState } from '../section-layout/section-layout.component';

@Component({
  selector: 'app-production-division',
  templateUrl: './production-division.component.html',
  styleUrls: ['./production-division.component.scss']
})
export class ProductionDivisionComponent {
  @Input() graphData!: GraphContainer;
  public currentBuIndex: number = 0;
  public currentSbuIndex: number = 0;
  public isLoading: boolean = true;

  public navState: NavigationOptions = {};

  constructor(private cdr: ChangeDetectorRef) { }

  public updateNavState(state: NavigationOptions){
    this.navState = state;
    if(this.navState.bu !== undefined){
      this.currentBuIndex = +this.navState.bu;
    }
    if(this.navState.sbu !== undefined){      
      this.currentSbuIndex = +this.navState.sbu;
    }
    this.cdr.detectChanges();    
  }

  public onIntersectionChange(event: IntersectionState): void {
    if(event === IntersectionState.Inside) {
      this.isLoading = false;
    }
  }
}
