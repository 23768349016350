<div class="bar-chart-container" [ngClass]="{'positive' : value > 0 , 'negative' : value < 0}">
    <div class="bar-item-container" *ngIf="value; else noData">
        <div class="width-100-and-flex">
            <div [hidden]="value > 0" class="bar-item"
                [style.width]="getChangeWith(value) + '%'"></div>
            <span *ngIf="value < 0" >{{value | handleValueSignForMultiBar:symbolForBar }}</span>
        </div>
        <div class="horizontal-axis"></div>
        <div class="width-100-and-flex">
            <div [hidden]="value < 0" class="bar-item"
                [style.width]="getChangeWith(value) + '%'"></div>
            <span *ngIf="value > 0">{{value | handleValueSignForMultiBar:symbolForBar}}</span>
        </div>
    </div>
</div>
<ng-template #noData>
    <span>no data...</span>
</ng-template>