<app-section-layout [dataForChart]="graphData" [itemTemplate]="epsGraph" (onIntersection)="onIntersectionChange($event)" ></app-section-layout>

<ng-template
 #epsGraph
 let-data="data"
 let-setComponentState="setComponentState"
 let-componentState="componentState"
>
    <app-eps-slide
     [data]="data"
     (onUserSelectedData)="setComponentState($event)" 
     [currentData]="componentState"
     [isLoading]="isLoading"
    ></app-eps-slide>
</ng-template>