<app-section-layout
  [dataForChart]="graphData"
  [itemTemplate]="netIncomeGraph"
  (onIntersection)="onIntersectionChange($event)"
></app-section-layout>

<ng-template
  #netIncomeGraph
  let-data="data"
  let-componentState="componentState"
  let-setComponentState="setComponentState"
>
  <app-vertical-bar-graph
    [graph]="data.graph.data"
    (onBarSelect)="onSelectGarphValue($event)"
    (onUserBarSelect)="setComponentState($event)"
    [userData]="componentState"
    [type]="getBarType(data)"
    [isLoading]="isLoading"
  ></app-vertical-bar-graph>
</ng-template>
