
<ng-container *ngIf="!isLoading">
<div echarts [id]="chartId" [options]="options" [autoResize]="false" class="chart-container"></div>
<div class="content-chart">
    <span class="presentage-value">
        {{data.value}}%
    </span>
    <span class="chart-title">
        {{data.title}}
    </span>
</div>
</ng-container>
<ng-container *ngIf="isLoading">
    <div class="container">
        <app-loader></app-loader>
    </div>
</ng-container>
