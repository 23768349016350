<div echarts [options]="options" class="chart-container" (chartInit)="onChartInit($event)" [autoResize]="false" [merge]="mergeOptions"></div>
<div class="general-info">
    <div class="col">
        <span class="title">Add Up</span>
        <span class="addup">{{data.addUp}}</span>
    </div>
    <div class="separator"></div>
    <div class="col">
        <span class="title">Budget</span>
        <span class="budget">{{data.budget}}</span>
    </div>
</div>
