import { Component, Input } from '@angular/core';
import { GraphContainer } from 'src/app/model/generic-graph';

@Component({
  selector: 'app-icl-fcf',
  templateUrl: './icl-fcf.component.html',
  styleUrls: ['./icl-fcf.component.scss']
})
export class ICLFcfComponent {
  @Input() graphData!: GraphContainer;
}
