import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { GraphView } from 'src/app/model/generic-graph';
import { NavigationOptions } from 'src/app/model/queryParams';
import { VerticalGraphType } from 'src/app/model/vertical-views';
import { IntersectionState } from '../section-layout/section-layout.component';

@Component({
  selector: 'app-production-division-slide',
  templateUrl: './production-division-slide.component.html',
  styleUrls: ['./production-division-slide.component.scss']
})
export class ProductionDivisionSlideComponent {
  @Input() data!: any;
  @Input() navInfo!: NavigationOptions;
  @Output() onUserSelectedData = new EventEmitter<any>();
  @Input() currentBUIndex: number = 0;
  @Input() currentSBUIndex: number = 0;
  @Input() isLoading: boolean = true;
  @Input() set currentData(data: any) {
    if(data && data.colName) {
      const currentGraphIndex = this.data.graph.data[this.currentBUIndex].data[this.currentSBUIndex].data.data.findIndex(
        (item: any) => item.colName === data.colName
      );
      if(currentGraphIndex > -1 && this.currentSelectedColumnIndex !== currentGraphIndex) {
        this.onSelectGarphValue(currentGraphIndex);
      }
    }
  };

  public currentSelectedColumnIndex!: number;  

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initDefaultNavInfo(); 
    this.initCurrentColumnIndex();
  }

  /**
   * Initialize bar index and selected business unit, if graph was directly navigated to through url
   */
  private initDefaultNavInfo(): void {
      if (this.navInfo.column !== undefined && this.navInfo.column > -1 && this.navInfo.column < this.data.graph.data[this.currentBUIndex].data[this.currentSBUIndex].data.data.length) {
        this.onSelectGarphValue(this.navInfo.column);
      } 
  }

  private initCurrentColumnIndex(): void {
    if(!this.currentSelectedColumnIndex && this.currentSelectedColumnIndex !== 0) {
      const currentColumnIndex = this.data.graph.data[this.currentBUIndex].data[this.currentSBUIndex].data.data.findIndex((item: any) => item.isDefault);
      if(currentColumnIndex > -1) {
        this.onSelectGarphValue(currentColumnIndex);
      } else {
        this.onSelectGarphValue(0);
      }
    }
  }

  public onSelectGarphValue(index: number): void {
    this.currentSelectedColumnIndex = index;
    this.cdr.detectChanges();
  }

  public onUserBarSelect(columnData: any): void {
    this.onUserSelectedData.emit(columnData);
  }

  public getBarType(data: GraphView): VerticalGraphType {
    if(data.title === "LY" || data.title === "BGT") {
      return VerticalGraphType.COMPARE_BAR;
    } else {
      return VerticalGraphType.SIMPLE_BAR;
    }
  }

}
