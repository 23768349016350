import { trigger, state, style, transition, animate } from "@angular/animations"

export const Animations = {
    openClose: trigger('openClose', [
        state('open', style({ height: '*'})),
        state('close', style({ height: '0px'})),
        transition('open => close', [animate('0.8s ease')]),
        transition('close => open', [animate('0.8s ease')]),
    ]),

    visibleHidden: trigger('visibleHidden',[
        state('hidden', style({ transform: 'translateY(-100%)', visibility:'hidden'})),
        state('visible', style({ transform: 'translateY(0)', visibility: 'visible'})),
        transition('* => *', [animate('0.2s ease-in')]),
    ]),

    fadeInOut: trigger('fadeInOut', [
        state('in',style({ opacity: 1,})),
        transition('void => *', [style({ opacity: 0 }), animate('0.5s')]),
        transition('* => void', [animate('0.5s'), style({ opacity: 0 })]),
    ]),
};
