<app-pi-graph
    *ngIf="!data.graph.pieGraphs[selectedColoumnIndex].isCompare"
    [data]="this.data.graph.pieGraphs[selectedColoumnIndex].data"
    (onPieClick)="selectBusinessGroup($event)"
    [selectedIndex]="selectedPieIndex"
    [isLoading]="isLoading"
    >
</app-pi-graph>

<app-comm-spec-compare-graph
    *ngIf="data.graph.pieGraphs[selectedColoumnIndex].isCompare"
    [data]="data.graph.pieGraphs[selectedColoumnIndex].data"
    [selectedType]="selectedPieIndex"
    (onBarClick)="selectBusinessGroup($event)">
</app-comm-spec-compare-graph>

<app-vertical-bar-graph
        [graph]="this.data.graph.graphs[this.selectedPieIndex]"
        [selectedColumnIndex]="selectedColoumnIndex"
        [isSecondary]="selectedPieIndex === 1"
        (onBarSelect)="onSelectGarphValue($event)"
        (onUserBarSelect)="onUserBarSelect($event)"
        [isLoading]="isLoading"
        [isPercentage]="isPercentage"
        >
</app-vertical-bar-graph>

<app-financial-data-table
    [data]="data.graph.blocks[selectedColoumnIndex]"
    [selectedType]="selectedPieIndex"
    [isPercentage]="isPercentage"
    [isCompare]="data.graph.pieGraphs[selectedColoumnIndex].isCompare">
</app-financial-data-table>
