<div class="section-header" #header>
  <div class="section-header-inner" *ngIf="sectionTitle && hasDropdown">
    <h2 class="section-title">
      {{ dataForChart.type !== graphTypes.SBU ? sectionTitle : "" }}
    </h2>
  </div>

  <div class="section-header-inner">
    <h2 class="section-title" *ngIf="sectionTitle && !hasDropdown">
      {{ dataForChart.type !== graphTypes.SBU ? sectionTitle : "" }}
    </h2>

    <div *ngIf="hasDropdown" class="dropdown-oi-container">
      <button (click)="toggleDropdown()" class="arrow">
        <span>{{
          dataForChart.type === graphTypes.SBU
            ? dropdownOption[currentDropdownIndex] + " by SBU"
            : "by " + dropdownOption[currentDropdownIndex]
        }}</span>
        <img
          [ngClass]="{ 'is-dropdown-open': isDropdownOpen }"
          src="assets/icons/chevron.svg"
          alt="toggle dropdown options button"
        />
      </button>
      <div *ngIf="isDropdownOpen" class="buttons-dropdwon">
        <button
          *ngFor="let btnTitle of dropdownOption; let i = index"
          (click)="changeDataByDropdown(i)"
          [ngClass]="{ 'selected-option': currentDropdownIndex === i }"
          class="option-btn"
        >
          <img
            *ngIf="currentDropdownIndex === i"
            src="assets/images/checked.png"
            alt="checked option"
            class="checked-icon"
          />
          <span>{{ btnTitle }}</span>
        </button>
      </div>
    </div>

    <div *ngIf="timeTabs.length > 0" class="time-tabs" role="tablist">
      <button
        *ngFor="let tab of timeTabs; let index = index"
        type="button"
        role="tab"
        [attr.aria-selected]="index === currentTab"
        [attr.aria-controls]="sectionTitle + '_tabpanel'"
        [id]="tab.key"
        [tabindex]="index === currentTab ? 0 : -1"
        class="tab"
        [ngClass]="{ active: index === currentTab }"
        (click)="changeTab(index)"
        [disabled]="tab.disabled"
      >
        {{ tab.name }}
      </button>
    </div>
  </div>
</div>

<div
  class="section-content"
  [id]="sectionTitle + '_tabpanel'"
  role="timeTabs.length > 0 ? 'tabpanel' : ''"
  [attr.aria-labelledby]="timeTabs.length > 0 && timeTabs[currentTab].key"
>
  <app-business-unit-navigation
    [currentUnitIndex]="currentBUIndex"
    (currentUnitIndexChange)="onChangeUnit($event)"
    (currentSubUnitIndexChange)="onChangeSubUnit($event)"
    [(currentSubUnitIndex)]="currentSBUIndex"
    [businessUnits]="getBusinessUnits()"
    [subBusinessUnits]="getSubBusinessUnits()"
  >
  </app-business-unit-navigation>
  <!-- <ng-container *ngFor="let navItem of navData; let index = index">
    <ng-container *ngIf="currentTab === index && navItem && navItem.length > 0"> -->
      <ng-container>
      <ng-container>
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="sliderCountHandle(navData[currentTab].length)"
        (init)="onSlickInit($event)"
        (afterChange)="afterChange($event)"
      >
        <div
          *ngFor="let slide of navData[currentTab]; let index = index; trackBy: trackByFunc"
          ngxSlickItem
          class="slider"
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                data: slide,
                navInfo: navInfo.slide === index ? navInfo : {},
                sectionNavState: getCurrentNavState(),
                componentState: componentState,
                setComponentState: setComponentState.bind(this)
              }
            "
          ></ng-container>
        </div>
      </ngx-slick-carousel>
    </ng-container>
  </ng-container>
</div>
