import { Component, Input } from '@angular/core';
import { GraphContainer } from 'src/app/model/generic-graph';

@Component({
  selector: 'app-potash-prices',
  templateUrl: './potash-prices.component.html',
  styleUrls: ['./potash-prices.component.scss']
})
export class PotashPricesComponent {
  @Input() graphData: GraphContainer = {} as GraphContainer;
}
