import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TerritoryRegion } from 'src/app/model/territory-graph';


/**
 * @description
 * This component acts as a container for a world map graph and displays the data about each continent.
 * On clicking some continent her data displays on the screen
 */

@Component({
  selector: 'app-territory-slide',
  templateUrl: './territory-slide.component.html',
  styleUrls: ['./territory-slide.component.scss'],
})
export class TerritorySlideComponent implements OnInit {
  public countryColorAndFocus: string = '#024266';
  public countryDataIndex: number = 0;
  @Input() public selectedContinental: any = 0;
  public dataIndex: number = this.selectedContinental ? this.selectedContinental.continentIndex : 0;
  public continents: TerritoryRegion[] = [];

  @ViewChild('mapContainer') mapContainer: any;



  @Input() data: any;


  @Output() public selectedContinentalChange: EventEmitter<any> = new EventEmitter<any>();


  ngOnChanges(changes: any): void { 
    if(changes['data'] ) {
      this.continents = [this.data.graph.northAmerica, this.data.graph.southAmerica, this.data.graph.europe, this.data.graph.asia, this.data.graph.restOfTheWorld, this.data.graph.all];
      setTimeout(() =>{
        if(this.selectedContinental) {
          this.setSelectedContinental(null, this.selectedContinental.continentIndex, false);
          this.showPresentageChages(this.selectedContinental.countryDataIndex);
        } else {
          this.setSelectedContinental(null, this.dataIndex, false)
        }
      })
    }
  }

  ngOnInit(): void {
    this.continents = [this.data.graph.northAmerica, this.data.graph.southAmerica, this.data.graph.europe, this.data.graph.asia, this.data.graph.restOfTheWorld, this.data.graph.all];
    setTimeout(() =>{
      if(this.selectedContinental) {
        this.setSelectedContinental(null, this.selectedContinental.continentIndex, false);
        this.showPresentageChages(this.selectedContinental.countryDataIndex);
      } else {
        this.setSelectedContinental(null, this.dataIndex, false)
      }
    })
   }

  public setSelectedContinental(element: any, index: number, shouldEmit = true): void {
    this.dataIndex = index;
    const container = this.mapContainer.nativeElement;
    const restOfTheWorldIndex = 4;
    const allIndex = 5;

    container
    .querySelectorAll('.country-area')
    .forEach((countryElement: any) => {
      //HTMLElement
      countryElement.classList.remove('focused');
    });
    const elements = container.querySelectorAll('.map-container > div');

    switch (index) {
      case allIndex:
        break;
      case restOfTheWorldIndex:
        elements[4].querySelectorAll('.country-area').forEach((el: any) => el.classList.add('focused'));
        elements[5].querySelectorAll('.country-area').forEach((el: any) => el.classList.add('focused'));
        break;
      default:
        elements[index].querySelectorAll('.country-area').forEach((el: any) => el.classList.add('focused'));
        break;
    }

    this.countryDataIndex = 0;
    if(shouldEmit) {
      this.selectedContinentalChange.emit({continentIndex: index, countryDataIndex: this.countryDataIndex});
    }
  }

  public showPresentageChages(index: number): void {
    this.countryDataIndex = index;
  }
}
