import { Component, Input } from '@angular/core';
import { GraphContainer } from 'src/app/model/generic-graph';

@Component({
  selector: 'app-territory-graph',
  templateUrl: './territory-graph.component.html',
  styleUrls: ['./territory-graph.component.scss']
})
export class TerritoryGraphComponent {

  @Input() territoryBarData: any;
 
}
