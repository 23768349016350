import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ComSpecBlockType } from 'src/app/model/commodities-graph';
import { GraphView } from 'src/app/model/generic-graph';
import { FCF } from 'src/app/model/kpi-graphs';
import { NavigationOptions } from 'src/app/model/queryParams';
import { VerticalGraph } from 'src/app/model/vertical-views';

@Component({
  selector: 'app-fcf-slide',
  templateUrl: './fcf-slide.component.html',
  styleUrls: ['./fcf-slide.component.scss']
})
export class FcfSlideComponent {
  @Input() data!: GraphView;
  @Input() colIndex!: number;
  @Output() onUserSelectedData = new EventEmitter<any>();
  @Input() isLoading: boolean = true;

  private currentDataValue: any;

  @Input() set currentData(data: any) {
    this.currentDataValue = data;
    if(data && data.colName) {
      const currentGraphIndex = this.data.graph.data.graph.data.findIndex(
        (item: any) => item.colName === data.colName
      );
      if(currentGraphIndex > -1 && this.colIndex !== currentGraphIndex) {
        this.selectBar(currentGraphIndex);
      }
    }
};

  public selectedBusinessGroup: string = ComSpecBlockType[0];
  public currentVerticalGraph: VerticalGraph | null = null;
  public types: any = ComSpecBlockType;
    
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initCurrentColumnIndex();
  }

  ngOnChanges(changes: any): void {
    if(changes["data"]) {
      if(this.currentDataValue) {
        const currentGraphIndex = this.data.graph.data.graph.data.findIndex(
          (item: any) => item.colName === this.currentDataValue.colName
        );
        if(currentGraphIndex > -1) {
          this.selectBar(currentGraphIndex);
        } else {
          this.selectDefaultBar();
        }
      }
      else {
        this.initCurrentColumnIndex();
      }
    }
  }

  private initCurrentColumnIndex(): void {
    if(!this.colIndex && this.colIndex !== 0) {
      const currentColumnIndex = this.data.graph.data.graph.data.findIndex((item: any) => item.isDefault);
      if(currentColumnIndex > -1) {
        this.selectBar(currentColumnIndex);
      } else {
        this.selectBar(0);
      }
    }
  }

  selectBar(index: number){
    this.colIndex = index;
    this.cdr.detectChanges();
  }

  selectDefaultBar(): void {
    const currentColumnIndex = this.data.graph.data.graph.data.findIndex((item: any) => item.isDefault);
      if(currentColumnIndex > -1) {
        this.selectBar(currentColumnIndex);
      } else {
        this.selectBar(0);
      }
  }

  public onUserBarSelect(columnData: any): void {
    this.onUserSelectedData.emit(columnData);
  }
}
