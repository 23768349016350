import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationOptions } from 'src/app/model/queryParams';
import { SummaryBattery, SummaryBatteryItem } from 'src/app/model/vertical-views';

@Component({
  selector: 'app-battery-graph',
  templateUrl: './battery-graph.component.html',
  styleUrls: ['./battery-graph.component.scss']
})
export class BatteryGraphComponent{

  public isTwoColumns: boolean = true;
  
  /**
   * @property {NavigationOptions} navState contains the navigtaion info for the currently displyed graph (time tab, slide, dropdown and graph name)
   */
  @Input() columnIndex!: number;
  @Input() isMainGraph!: boolean;
  @Input() navState: NavigationOptions = {};
  @Input() isLoading!: boolean;
  
  private _batteries!: SummaryBattery;
  @Input()
  set batteries(data: SummaryBattery) {
    if(data) {
      this._batteries = {
        ...data,
        columns: data.columns.map(item => {
          return {
            ...item,
            negative: item.value < 0,
            size: Math.abs(item.value) < 100 ? Math.abs(item.value) : 100,
          };
        })
      }
    }
    this.isTwoColumns = this._batteries?.colNames.length === 2;
  }
  get batteries(): SummaryBattery {
    return this._batteries;
  }

  constructor(private router: Router, private ngZone: NgZone) {};

  trackByFn(index: number, item: any) {
    return index;
  }

  public onNavigateToGraph(item: SummaryBatteryItem): void { 
    if(!this.isMainGraph) {
      return;
    }

    this.ngZone.run(() =>
      this.router.navigate([], {
        queryParams: {
          nav: this.navState.nav,
          div: item.title,
          slide: this.navState.slide,
          graph: this.navState.graph,
          column: this.columnIndex
        }
      })
    );
  }

}
