<ng-container *ngIf="data">
    <div [@openClose]="selectedItem && selectedItem.isMoreInfo ? 'open' : 'close'" class="more-info">
        <div class="units">
            <ng-container *ngFor="let unit of data.graphs | keyvalue: originalOrder">
                <button class="unit-button" *ngIf="data.graphs[unit.key]" (click)="updateUnit(unit.key)"
                    [ngClass]="{
                        'selected': currentUnit === unit.key,
                        'compare': data.fields[columnIndex][unit.key].salesCompare,
                        'down-gesture': data.fields[columnIndex][unit.key].positive < 0,
                        'up-gesture': data.fields[columnIndex][unit.key].positive > 0
                    }">
                    <span class="title">{{salesUnits[unit.key]}}</span>
                    <span class="sales">{{data.fields[columnIndex][unit.key].sales}}</span>
                    <span class="sales compare" *ngIf="data.fields[columnIndex][unit.key].salesCompare != null">{{data.fields[columnIndex][unit.key].salesCompare}}</span>
                </button>
            </ng-container>
        </div>
        <app-vertical-bar-graph
            *ngIf="data.graphs[currentUnit]"
            [graph]="data.graphs[currentUnit]"
            [selectedColumnIndex]="columnIndex"
            (onBarSelect)="selectGraphValue($event)"
            (onUserBarSelect)="onUserBarSelect($event)"
            [isLoading]="isLoading || !(selectedItem && selectedItem.isMoreInfo)"
            >
        </app-vertical-bar-graph>
    </div>

    <div class="toggle-wrapper" [ngClass]="{'open': selectedItem && selectedItem.isMoreInfo }">
        <button type="button" (click)="toggleMoreInfo()" class="more-info-button">
            <img [src]="selectedItem && selectedItem.isMoreInfo ? './assets/icons/chevron-up.svg' : './assets/icons/chevron-down.svg'"
            [alt]="selectedItem && selectedItem.isMoreInfo ? 'open' : 'close'" />
        </button>
    </div>
</ng-container>
