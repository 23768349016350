import { NavigationItem } from "./production-views";
import { EPSGraphView, VerticalGraph } from "./vertical-views";

export interface Graph {
  data: any;
  isMainGraph?: boolean;
}

export interface GraphContainer {
  title: String;
  type: GraphType;
  navigation: Navigation;
  subMenu?: NavigationItem<any>[];
}

export interface GraphMenu extends Array<GraphMenuItem> {};
export interface GraphViews extends Array<GraphView> {};


export interface GraphNavigation {
  month?: GraphViews;
  qtd?: GraphViews;
  ytd?: GraphViews;
}

export interface Navigation {
  type: NavigationType;
  nav: GraphNavigation | GraphViews | GraphMenu;
}

export interface GraphMenuItem {
  title: String;
  data: GraphNavigation | GraphViews;
  isPercentage?: boolean; // used for commodities graph
}

export interface GraphView {
  title: String;
  graph: Graph | EPSGraphView;
}



/**
 * Contains types of all graphs by name
 */
export enum GraphType {
  Sales = 'Sales',
  OI = 'OI',
  EBITDA = 'EBITDA',
  EPS = 'EPS',
  NI = 'NI',
  SBU = 'SBU',
  TOP_PRODUCTS = 'TOP_PRODUCTS',
  Commodities = 'Commodities',
  TERRITORY = 'TERRITORY',
  FCF= 'FCF',
  ICL_FCF='ICL_FCF',
  POTASH_PRICES = 'POTASH_PRICES',
  TOP_CUSTOMERS = 'TOP_CUSTOMERS',
  KPI = "KPI",
  POTASH_AVG = 'POTASH_AVG',
  PRODUCTION_KTM= 'PRODUCTION_KTM',
  RT_PRODUCTION = 'RT_PRODUCTION',
  PRODUCTION_DIVISION = 'PRODUCTION_DIVISION',
  CAPEX = 'CAPEX'
}

export enum NavigationType {
  GraphNavigation = 'GraphNavigation',
  GraphViews = 'GraphViews',
  GraphMenu = 'GraphMenu'
}

export enum GraphViewType {
  'vertical',
}

export interface GraphColumn {
  colName: String;
  colGroupName: String;
}

export enum TimePeriods {
  month = 'M',
  qtd = 'QTD',
  ytd ='YTD',
}

export enum Divisions {
  ICL = 'ICL',
  IP = 'IP',
  POTASH = 'POTASH',
  PS = 'PS',
  GS = 'GS'
}
