<ng-container *ngIf="sbuGraph && !isLoading" >
    <div class="column-name-row" >
        <span class="title" ></span>
        <span class="column" *ngFor="let name of sbuGraph.colNames">{{name}}</span>
        <span class="value"></span>
        <div *ngIf="sbuGraph.isMainGraph" class="pointer"></div>
    </div>
    <div class="group" *ngFor=" let group of sbuGraph.data" >
        <div class="separator">
            <span class="group-title">{{group.title}}</span>
        </div>
        <app-battery *ngFor=" let item of group.data" [item]="item" [sbuBattery]="true" [isMainGraph]="true" (click)="onNavigateToGraph(group.title, item.title)" [columnNames]="sbuGraph.colNames"></app-battery>
    </div>
</ng-container>
<ng-container *ngIf="isLoading">
    <div class="sbu-container">
        <app-loader></app-loader>
    </div>
</ng-container>
