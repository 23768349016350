import { Component, Input } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-half-doughunt-graph',
  templateUrl: './half-doughunt-graph.component.html',
  styleUrls: ['./half-doughunt-graph.component.scss'],
})
export class HalfDoughuntGraphComponent {
  @Input() data!: any;
  @Input() chartId: string = '';
  @Input() isLoading: boolean = true;

  public options: EChartsOption = {};

  public baseOptions: any = {
    tooltip: {
      trigger: 'item',
    },
    color: [
      {
        colorStops: [
          {
            offset: 0,
            color: '#16657e',
          },
          {
            offset: 0.5,
            color: '#00deef',
          },
        ],
        x: 0,
        y: 0.5,
        x2: 0.8,
        y2: 0,
        type: 'linear',
        global: false,
      },
      '#002C4C',
    ],

    legend: {
      top: '5%',
      left: 'center',
      selectedMode: false,
    },
    series: [
      {
        type: 'pie',
        // adjust the start angle
        startAngle: 180,
        radius: [50, 70],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: [],
      },
    ],
  };

  ngOnInit(): void {
    this.baseOptions.series[0].data = [
      { value: this.data.value }, // value
      { value: this.data.total - this.data.value }, // complementary value
      {
        // half hiddean pie graph
        // make an record to fill the bottom 50%
        value: this.data.total,
        itemStyle: {
          // stop the chart from rendering this piece
          color: 'none',
          decal: {
            symbol: 'none',
          },
        },
        label: {
          show: false,
        },
      },
    ];
    this.options = { ...this.baseOptions };
  }
}
