import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { GraphView } from 'src/app/model/generic-graph';
import { NavigationOptions } from 'src/app/model/queryParams';
import { EPSDataItem, EPSGraphView, VerticalGraphType } from 'src/app/model/vertical-views';

/**
 * @description
 * This component acts as a container for vertical bar graph and its corresponding footer's deatils.
 * It handles the connection between the two, according to the currently selected bar.
 */
@Component({
  selector: 'app-eps-slide',
  templateUrl: './eps-slide.component.html',
  styleUrls: ['./eps-slide.component.scss']
})
export class EpsSlideComponent {
  /**
   * data is an object of @see GraphView interface, containing a vertical bar graph and footer's details ( @see EPSDataItem )
   */
  @Input() data!: GraphView;
  @Input() isLoading: boolean = true;
  public currentSelectedColumnIndex!: number;
  @Output() onUserSelectedData = new EventEmitter<any>();
  private currentDataValue: any;
  @Input() set currentData(data: any) {
    this.currentDataValue = data;
    if(data && data.colName) {
      const currentGraphIndex = this.data.graph.data.data.findIndex(
        (item: any) => item.colName === data.colName
      );
      if(currentGraphIndex > -1 && this.currentSelectedColumnIndex !== currentGraphIndex) {
        this.onSelectGarphValue(currentGraphIndex);
      }
    }
};

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initCurrentColumnIndex()
  }

  ngOnChanges(changes: any): void {
    if(changes['data']) {
      if(this.currentDataValue) {
        const columnIndex = this.data.graph.data.data.findIndex( (item: any) => item.colName === this.currentDataValue.colName);
        if(columnIndex > -1) {
          this.onSelectGarphValue(columnIndex);
        } else {
          this.selectDefaultColumn();
        }
      } else {
        this.selectDefaultColumn();
      }
    }
  }

  private initCurrentColumnIndex(): void {
    if(!this.currentSelectedColumnIndex && this.currentSelectedColumnIndex !== 0) {
      this.selectDefaultColumn();
    }
  }

  private selectDefaultColumn(): void {
    const defaultColumnIndex = this.data.graph.data.data.findIndex((item: any) => item.isDefault);
    if(defaultColumnIndex > -1) {
      this.onSelectGarphValue(defaultColumnIndex);
    } else {
      this.onSelectGarphValue(0);
    }
  }

  public onSelectGarphValue(columnIndex: number): void {
    this.currentSelectedColumnIndex = columnIndex;
    this.cdr.detectChanges();
  }

  get footerData(): EPSDataItem {
    return (this.data.graph as EPSGraphView).footer[this.currentSelectedColumnIndex];
  }

  public onUserBarSelect(columnData: any): void {
    this.onUserSelectedData.emit(columnData);
  }

  public getBarType(data: GraphView): VerticalGraphType {
    if (data.title === 'LY' || data.title === 'BGT') {
      return VerticalGraphType.COMPARE_BAR;
    } else {
      return VerticalGraphType.SIMPLE_BAR;
    }
  }
}
