<div class="graph-container"
    [ngClass]="{
        'commodities-selected': selectedType === 0,
        'specialties-selected': selectedType === 1}">

    <div class="header">
        <span class="title commodities">{{data[commoditiesIndex].title}}</span>
        <span class="title specialties">{{data[specialtiesIndex].title}}</span>
    </div>

    <div class="bar">
        <div class="commodities" (click)="selectBusinessGroup(data[commoditiesIndex])"
            [style.width.%]="data[commoditiesIndex].percent || 50">
                {{data[commoditiesIndex].percent}}%
        </div>
        <div class="specialties" (click)="selectBusinessGroup(data[specialtiesIndex])"
            [style.width.%]="data[specialtiesIndex].percent || 50">
                {{data[specialtiesIndex].percent}}%
        </div>
    </div>

    <div class="bar">
        <div class="commodities compare" (click)="selectBusinessGroup(data[commoditiesIndex])"
            [style.width.%]="data[commoditiesIndex].percentCompare || 50">
                {{data[commoditiesIndex].percentCompare}}%
        </div>
        <div class="specialties compare" (click)="selectBusinessGroup(data[specialtiesIndex])"
            [style.width.%]="data[specialtiesIndex].percentCompare || 50">
                {{data[specialtiesIndex].percentCompare}}%
        </div>
    </div>
</div>
