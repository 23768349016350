import { Component } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'VIBEs';
  loginStatus: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(private broadcastService: MsalBroadcastService, private msalService: MsalService, private router: Router) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('token');
    token ? localStorage.setItem('sso.idtoken', token) : '';

    //logout if detect no status
    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.loginStatus = this.msalService.instance.getAllAccounts().length > 0;
      if(!this.loginStatus){
        this.router.navigate(['/']);
      }
    })

    this.broadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => {
        return (
          msg.eventType === 'msal:loginSuccess' ||
          msg.eventType === 'msal:loginFailure' ||
          msg.eventType === 'msal:ssoSilentSuccess' ||
          msg.eventType === 'msal:ssoSilentFailure' ||
          msg.eventType === 'msal:accountAdded' ||
          msg.eventType === 'msal:accountRemoved'
        );
      })
    )
    .subscribe((result: EventMessage) => {
      const loginStatus =
        this.msalService.instance.getAllAccounts().length > 0;
      if (loginStatus) {
        const profile_data = this.msalService.instance.getAllAccounts()[0];
        this.msalService.instance.setActiveAccount(profile_data);
        this.router.navigate(['/dashboard']);
      } else {
        console.log('Not logged-in');
        this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
