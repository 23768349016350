<table class="prices-table" *ngIf="data">
    <thead class="table-headers">
        <tr>
            <th class="main-header" colspan="5"><span>{{data.colNames[0]}}</span></th>
        </tr>
        <tr>
            <th *ngFor="let col of data.colNames.slice(1, data.colNames.length)" class="col-header">{{col}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of data.data.rows; let index = index;" class="row-container">
            <td class="data-table-container">
                <table class="data-table">
                    <thead>
                        <tr class="main-data-row" [ngClass]="{'open': index === currentIndex}">
                            <th>
                                <button (click)="toggleRecordSelection(index)" class="toggle-btn">
                                    <img [src]="index === currentIndex ? './assets/icons/chevron-up-blue.svg' : './assets/icons/chevron-right.svg'"
                                        alt="" />
                                    <span>{{row.title}}</span>
                                </button>
                            </th>
                            <th *ngFor="let value of row.data" class="value" [style.background-color]="eVolumeFun(value)">
                                {{value}}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="sub-data-row" *ngIf="index === currentIndex">
                        <tr [@openClose]="index === currentIndex ? 'open' : 'close'">
                            <td class="title">KMT</td>
                            <td *ngFor="let value of row.kmt" class="value">{{value}}</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
