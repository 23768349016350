import { Component, Input, SimpleChanges } from '@angular/core';
import { NavigationItem, RTSparkChart } from 'src/app/model/production-views';
import { NavigationOptions } from 'src/app/model/queryParams';

@Component({
  selector: 'app-real-time-production',
  templateUrl: './real-time-production.component.html',
  styleUrls: ['./real-time-production.component.scss']
})
export class RealTimeProductionComponent {
  @Input() graphData: NavigationItem<RTSparkChart>[] = [];
  @Input() sectionNavState: NavigationOptions = {} as NavigationOptions;
  @Input() selectedBUIndex: number = 0;
  @Input() selectedSBUIndex: number = 0;
  @Input() isLoading :boolean = true;

  ngOnChanges(changes: SimpleChanges): void{
    if(changes["selectedBUIndex"] ){
     this.selectedBUIndex= changes["selectedBUIndex"].currentValue;
    }
    if(changes["selectedSBUIndex"] ){
      this.selectedSBUIndex= changes["selectedSBUIndex"].currentValue;
     }
  }
}
