import { Component, Input, ElementRef, AfterViewInit, OnChanges, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-number-counter',
  templateUrl: './number-counter.component.html',
  styleUrls: ['./number-counter.component.scss'],
})
export class NumberCounterComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() countValue!: number;
  @Input() duration: number = 2000;
  @Input() shouldPlay?: boolean;
  playing = false;
  destroyed = false;
  startingValue = 0;
  shouldCompute = false;

  constructor(
    private nativeElementRef: ElementRef,
  ) {
  }

  ngOnChanges(): void {
    if(this.shouldPlay) {
      if(!this.playing) {
        this.start();
      } else {
        this.shouldCompute = true;
      }
    }

    
  }
  
  ngAfterViewInit(): void {
      if(this.shouldPlay) {
        this.start();
      }
  }

  ngOnDestroy(): void {
      this.destroyed = true;
  }

  start(): void {
    if(this.playing) {
      return;
    }
    if(this.destroyed) {
      return;
    }
    this.playing = true;
    const targetValue = this.countValue;
    const duration = this.duration;
    let currentCount = this.startingValue;
    const element = this.nativeElementRef.nativeElement;
    let previousTimestamp = performance.now();
    const range = targetValue - currentCount;
    
    const countNumbers = () => {
      if(this.shouldCompute) {
        this.startingValue = currentCount;
        this.shouldCompute = false;
        this.playing = false;
        this.start();
        return;
      }


      const currentTimestamp = performance.now();
      const deltaTime = currentTimestamp - previousTimestamp;
      const relativeProgress = deltaTime / duration;
      const deltaValue = relativeProgress * range;
      const distance = Math.abs(targetValue - currentCount);
      if(distance <= Math.abs(deltaValue)){
        currentCount = targetValue;
        element.innerHTML = Math.trunc(currentCount).toString();
        this.startingValue = targetValue;
        this.playing = false;
      }else {
        currentCount += deltaValue;
        element.innerHTML = Math.trunc(currentCount).toString();
        previousTimestamp = currentTimestamp;
        requestAnimationFrame(countNumbers);
      }
    };
    countNumbers();
  }
}
