<app-section-layout
  [dataForChart]="graphData"
  [itemTemplate]="commoditiesGraph"
  (onIntersection)="onIntersectionChange($event)"
  (updateNavState)="updateNavState($event)"
></app-section-layout>

<ng-template
  #commoditiesGraph
  let-data="data"
  let-navInfo="navInfo"
  let-componentState="componentState"
  let-setComponentState="setComponentState"
>
  <app-com-spec-slide
    [navState]="navState"
    [graphData]="graphData"
    [data]="data"
    (currentUserSelectedData)="setComponentState($event)"
    [userData]="componentState"
    [isLoading]="isLoading"
  ></app-com-spec-slide>
</ng-template>
