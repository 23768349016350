import { Component, Input } from '@angular/core';
import { GraphContainer, GraphType } from 'src/app/model/generic-graph';

@Component({
  selector: 'app-switch-graphs',
  templateUrl: './switch-graphs.component.html',
  styleUrls: ['./switch-graphs.component.scss']
})
export class SwitchGraphsComponent {
  @Input() graph!: GraphContainer;
  public graphType: any = GraphType;
}
