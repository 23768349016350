<app-vertical-bar-graph
    [graph]="data.graph.data.graph"
    [selectedColumnIndex]="colIndex"
    (onBarSelect)="selectBar($event)"
    (onUserBarSelect)="onUserBarSelect($event)"
    [isLoading]="isLoading"
>
</app-vertical-bar-graph>

<ng-container *ngIf="data.graph.data.blocks[colIndex] && data.graph.data.blocks[colIndex].blocks ">
    <ul class="fcf-table">
        <li *ngFor="let block of data.graph.data.blocks[colIndex].blocks" class="block">
            <span class="title">{{block.title}}</span>
            <span class="actual">{{block.values[0] | formatNegativeNumber}}</span>
            <span class="compare" *ngIf="block.values[1] !== null && block.values[1] !== undefined">{{block.values[1] | formatNegativeNumber}}</span>
        </li>
    </ul>
</ng-container>
