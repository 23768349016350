<app-section-layout
  [dataForChart]="graphData"
  [itemTemplate]="salesGraph"
  (dataChanged)="onDataChanged($event)"
  (updateNavState)="updateNavState($event)"
  (onIntersection)="onIntersectionChange($event)"
></app-section-layout>

<ng-template
  #salesGraph
  let-data="data"
  let-navInfo="navInfo"
  let-sectionNavState="sectionNavState"
  let-componentState="componentState"
  let-setComponentState="setComponentState"
>
  <app-vertical-view-slide
    [data]="data"
    [navInfo]="navInfo"
    [navState]="sectionNavState"
    [currentBUIndex]="currentBuIndex"
    (currentUserSelectedData)="setComponentState($event)"
    [currentData]="componentState"
    [isLoading]="isLoading"
  ></app-vertical-view-slide>
</ng-template>
